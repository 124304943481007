.bottomText {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 13px;
    font-size: 13px;
    font-weight: lighter;
    text-align: center;
    color: #ffffff;
    z-index: 20;
    opacity: 1;
    transition: all 0.2s linear;
}

.bottomLogo {
    width: 73px;
    margin-left: 10px;
    margin-right: 10px;
}

.versionText {
    margin-left: 7px;
    font-size: 10px;
    font-weight: lighter;
    text-align: center;
    color: #ffffff;
}


