* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  html {
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #000000;
  }
  
  body {
    background-color: #000000;
    height: 100%;
    /* overscroll-behavior : none; */
  }
  
  a {
    text-decoration: none;
  }
  
  #root {
    height: 100%;
  }

  *:focus {
    outline: none !important;
  }
  
  ::-webkit-scrollbar {
    display: none;
}